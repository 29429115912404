import * as THREE from 'three';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import Scene from './scenes/Scene';
import Loading from './scenes/Loading';
import { useEffect } from "react";

const loadingScene = new Scene({name:"Loading", scene: new Loading()});
var initial = false;
var logo = undefined;
var scene;
var camera;
var renderer;

const fLoader = new FontLoader();
const textureLoader = new THREE.TextureLoader();
const listener = new THREE.AudioListener();
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
var fontPage = undefined;
var loadingID = 0;
var loadingText = undefined;

var textResource = fLoader.load('jiki.json',
	// onLoad callback
	function ( font ) {
		console.log(font);
		fontPage = font;
	},

	// onProgress callback
	function ( xhr ) {

		console.log(xhr.loaded);
		console.log( xhr.loaded + ' loaded' );
	},

	// onError callback
	function ( err ) {
		console.log( 'An error happened' );
	}
);

const sound = new THREE.Audio(listener);
const audioLoader = new THREE.AudioLoader();
audioLoader.load('loading.ogg', function(buffer) {
  sound.setBuffer(buffer);
  sound.setLoop(true);
  sound.setVolume(0.5);
  sound.play();
});


function EngineComponent(currentScene) {

  function initialEngine(){
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
    renderer = new THREE.WebGLRenderer();
    camera.add(listener);
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild( renderer.domElement);
  }

  function rotateLogo() {
    requestAnimationFrame(rotateLogo);
    logo.rotation.z += 0.03;
    
  };

  function render(){
    requestAnimationFrame(render);
    camera.aspect = window.innerWidth / window.innerHeight;
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.render(scene, camera);
    sound.context.resume();
  }

  function loadText(){
    if(fontPage != undefined){
      cancelAnimationFrame(loadingID);
      console.log("definido");
      const textGeometry = new TextGeometry('Loading . . .', {
        font: fontPage,
        size: 1,
        height: 0.5
      });
  
      const textMaterial = new THREE.MeshBasicMaterial({ color: 0xf0f000 });
      loadingText = new THREE.Mesh(textGeometry, textMaterial);
      scene.add(loadingText);
      loadingText.position.z = -50;
      loadingText.position.y = -8;
      loadingText.position.x = -4;
      
    }else{
      loadingID = requestAnimationFrame(loadText);
    }
  }

  useEffect(() => {
    currentScene.scene.showName();
    if(!initial){
      initialEngine();
      initial = true;
    }

    if(logo == undefined){
      const texture = textureLoader.load('suriken.png');
      console.log(texture);
      const planeGeometry = new THREE.PlaneGeometry(1, 1);
      const planeMaterial = new THREE.MeshBasicMaterial({ 
        map: texture,
        transparent: true,
        side: THREE.DoubleSide
      });
      logo = new THREE.Mesh(planeGeometry, planeMaterial);
      scene.add(logo);
      logo.position.z = -1;
      camera.position.z = 5;
    }
    loadText();
    rotateLogo();
    render();
  }, [currentScene]);

  return (
    <div id="engine"></div>
  );
}

export default EngineComponent