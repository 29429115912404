import logo from './logo.svg';
import './App.css';
import Three from './EngineComponent';
import EngineComponent from './EngineComponent';
import Scene from './scenes/Scene';
import Home from './scenes/Home';
import {useState} from "react";

function App() {
  const homeScence = new Scene({name:"Home", scene: new Home()});
  const [currentScene, setCurrentScene] = useState(homeScence);

  return (
    <EngineComponent scene={currentScene}/>
  );
}

export default App;
