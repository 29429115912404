class Scene{

    constructor(configuracion){
        
        configuracion.initial = false;

        if(configuracion.name){
            this.name = configuracion.name;
            console.log("Defining scene: " + this.name)
        }
            

        if(configuracion.scene)
            this.scene = configuracion.scene;
        else 
            console.log("Scene not define in configuration.");
    }

    initialice(){
        if(this.scene)
            this.scene.initialice();
    }

    render(){
        if(this.scene)
            this.scene.render();
    }

    showName(){
        console.log(this.name);
    }
    
}

export default Scene;